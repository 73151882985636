<template>
    <div class="page_box">
      
        <div class="bom_box">
           
            <div class="bom_box_list">
                <el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
                    @selection-change="handle_list_change" v-loading="loading" >
                    <!-- <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column prop="name" label="姓名" width="150">
                        <template slot-scope="scope">
							<div slot="reference" class="name-wrapper" style="color: #2373C8;">
                                {{ scope.row.name }}
                            </div>
						</template>
                    </el-table-column> -->
                    <el-table-column prop="job_title" label="单位" width="500"></el-table-column>
                    <el-table-column prop="gw_title" label="岗位"></el-table-column>
                    <el-table-column prop="time" label="离职时间"></el-table-column>
                    <el-table-column prop="type" label="离职类型"></el-table-column>
                    <el-table-column prop="reason" label="离职原因"></el-table-column>
                    <el-table-column prop="order_no" label="文号" width="150"></el-table-column>
                    <el-table-column prop="pdf.name" label="文件名"width="200">
                        <template slot-scope="scope">
                            <el-popover trigger="hover" placement="top" :content="scope.row.pdf.name">
								<div slot="reference" class="name-wrapper2">
									<a :href="scope.row.pdf.path" target="_blank" style="color:#FF5E00">
										{{ scope.row.pdf.name }}
									</a>
								</div>
							</el-popover>
						</template>
                    </el-table-column>
                    <el-table-column prop="pdf_time" label="文件日期"></el-table-column>
                    
                </el-table>
                <div class="pages">
                    <page :pageson='count' @row="row" @currentPage="currentPage"></page>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import page from "@/components/page/page.vue"; //分页  统一的
 


export default {
    components: {
        page,
     


    },
    data() {
        return {
            loading: false,//加载总
            list: [
            ],
            pages: {
                page: 1,
                limit: 10,
            },
            count: 0,
        };
    },

    methods: {
        //接受条数
        currentPage(page) {
            console.log(page)
            this.pages.page = page
            this.get_list()
        },
        // 接收条数
        row(row) {
            console.log(row)
            this.pages.limit = row
            this.get_list()
        },
        //选择
        handle_list_change(e) {
            console.log('多选', e)
            this.check_list = e
        },
       
        //统一的列表接口
        get_list() {
            console.log(this.forms)
            this.loading = true
            this.$api("getUserDimission", {
                // mobile:this.mobile
                // ...this.forms,
				userId:this.$route.query.id,

                ...this.pages,
            }, "get").then((res) => {
                this.loading = false
                if (res.code == 200) {
                    this.count = res.data.count
                    this.list = res.data.list
                    this.list.forEach((e)=>{
                        try {
                            if(e.pdf){
                                e.pdf=JSON.parse(e.pdf)
                            }
                        } catch (error) {
                            e.pdf={}
                        }
                       
                    })
                } else {
                    alertErr(res.msg)
                }
            });
        },
        

    },
    computed: {
    },
    watch: {
    },
    created() {
        this.get_list()
    },
    mounted() {

    }


};
</script>

<style scoped lang="less">
.page_box {

    .bom_box {

        .bom_box_list {
            // width: 1640px;
            // height: 670px;
            padding: 20px 20px 32px 20px;

            .pages {
                padding-top: 30px;
            }
        }

    }
}
</style>